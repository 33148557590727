var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menuTemplate"},[_c('section',[(_vm.featuredScreen)?_c('div',{staticClass:"featuredScreen"},[_c('featuredParent',{attrs:{"featuredScreen":_vm.featuredScreen,"screenName":_vm.menu.id}})],1):_vm._e(),_c('div',{staticClass:"categoryScreens"},[_c('div',{staticClass:"categoryScreens-wrapper"},_vm._l((_vm.categoryScreens),function(item,index){return _c('div',{key:index},[(
            (item.sectionType === 'CONTINUEWATCH' || item.sectionType === 'CONTINUEWATCHING') && (item.status == 'ACTIVE' || item.isSectionEnabled)
          )?_c('continueSlider',{attrs:{"screen":item,"playerInstance":_vm.playerInstance}}):_vm._e(),(
            (item.sectionType !== 'CONTINUEWATCH' && item.sectionType !== 'CONTINUEWATCHING')  &&
              (item.isSectionEnabled || item.status == 'ACTIVE') &&
              (!item.userTypes ||
                (item.userTypes && item.userTypes.includes('ALL')) ||
                (item.userTypes && item.userTypes.includes(_vm.getUserType())))
          )?_c('slider',{attrs:{"screen":item,"id":index,"playerInstance":_vm.playerInstance,"screenName":_vm.menu.id}}):_vm._e()],1)}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }